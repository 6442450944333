import BodyText from 'firebird-ui/src/components/BodyText'
import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-block: 48px;
  column-gap: 32px;

  ${p => p.theme.mediaQueries.desktopXS} {
    flex-direction: column;
    padding-block: 32px;
    padding-inline: 0;
    row-gap: 24px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding: 16px;
  }
`

export const Description = styled(BodyText)`
  * {
    color: ${p => p.theme.colors.Surface100.$value};
  }

  ${p => p.theme.mediaQueries.desktopXS} {
    max-width: 100%;
  }
`

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  list-style-type: none;
  padding: 0;

  ${p => p.theme.mediaQueries.mobile} {
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'first first' 'third third';
  }
`
