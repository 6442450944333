import type { FC } from 'react'

import { useGetParagraphQuery } from '@redux/features/homePage/api'

import ArrowDownIcon from '@iconscout/unicons/svg/line/arrow-down.svg'

import { Cation, Container, Description, StyledLink, Wrapper } from './styles'

type Props = {
  itemId: number
}

export const ListItem: FC<Props> = ({ itemId }) => {
  const { data } = useGetParagraphQuery(itemId)

  return (
    <Container>
      <StyledLink href={data?.link?.uri || '/'}>
        <Wrapper>
          <Cation size="xsmall-strong">{data?.title}</Cation>
          <ArrowDownIcon />
        </Wrapper>
        <Description dangerouslySetInnerHTML={{ __html: data?.description || '' }} size="small-strong" />
      </StyledLink>
    </Container>
  )
}
