import BodyText from 'firebird-ui/src/components/BodyText'
import Link from 'next/link'
import styled from 'styled-components'

import { addAlpha } from '@utils/color/addAlpha'

export const Container = styled.li`
  min-width: 197px;
  border-radius: ${p => p.theme.borderRadius.sm};

  svg {
    transform: rotate(-135deg);
    fill: ${p => p.theme.colors.Brand100.$value};
  }

  &:first-child {
    border: 1px solid ${p => p.theme.colors.Surface10.$value};
  }

  &:nth-child(2) {
    background-color: ${p => addAlpha(p.theme.colors.yellow10, 0.1)};
  }

  &:last-child {
    background-color: ${p => p.theme.colors.Surface10.$value};
  }

  ${p => p.theme.mediaQueries.mobile} {
    &:first-child,
    &:last-child,
    &:nth-child(2) {
      background-color: transparent;
      border: 1px solid ${p => p.theme.colors.greyAdditionalTextDark};
      border-radius: ${p => p.theme.borderRadius.sm};

      a {
        padding-inline: 20px 10px;
      }
    }

    &:last-child {
      grid-area: third;

      a {
        padding-block: 16px;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  padding: 12px 16px;

  ${p => p.theme.mediaQueries.desktopXS} {
    padding-inline-start: 24px;
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Cation = styled(BodyText)`
  color: ${p => p.theme.colors.Brand100.$value};
`

export const Description = styled(BodyText)`
  &,
  * {
    color: ${p => p.theme.colors.onBackground.$value};
  }
`
